// only put action here if got problem with import cycle
import { Cookies } from "react-cookie";
import { dispatch } from "@redux/store";
import Api from "@helpers/api";
import { endpoints } from "@configs/endpoints";

export const apiGetProfile = (p) => {
  let onSuccess = () => null;
  let onFail = () => null;
  let token = null;
  if (!!p) {
    token = p.token;
    onSuccess = p.onSuccess;
    onFail = p.onFail;
  }
  const params = {
    endpoint: endpoints.getProfile(),
    onSuccess: (response) => {
      if (!!response.data.assets) {
        dispatch({ type: "annotationTypes/SET_ANNOTATION_TYPES", data: response.data.detection_types });

        const { assets } = response.data;

        for (let k = 0; k < assets.length; k++) {
          assets[k].latitude = parseFloat(assets[k].latitude);
          assets[k].longitude = parseFloat(assets[k].longitude);
          assets[k].company_id = response.data.id;
        }
        dispatch({ type: "assets/NEW_ASSETS_LIST", data: assets.sort((i) => (i.name == "SILK" ? 1 : -1)) });
        const user = response.data;
        const ck = new Cookies();
        const user_from_cookie = ck.get('user');

        if (user_from_cookie && user_from_cookie.token) {
          user.token = user_from_cookie.token;
          user.refresh_token = user_from_cookie.refresh_token
        }

        (new Cookies()).set("user", JSON.stringify({
          id: user.id,
          image: user.image,
          companies: user.companies,
          // TODO: why fail to save departments to cookie
          // departments: user.departments,
          username: user.username,
          name: user.name,
          role: user.role,
          token: user.token,
          refresh_token: user.refresh_token,
          access_annotations_type_list: user.access_annotations_type_list,
          email: user.email
        }), { path: "/" });
        dispatch({ type: 'SET_PROFILE', payload: user });
      }
      onSuccess(response);
    },
    onFail: (err) => onFail(err),
  };
  if (token && token.access_token) {
    params.token = token.access_token;
  }

  Api(params);
};
