import React, { Component, Suspense, useEffect } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { connect } from "react-redux";
import Loadable from "react-loadable";
import { routes } from "./routes";
import { isAuthenticated } from "@redux/selectors/auth";
import Firebase from "@components/firebase";

// Themes
import "./assets/scss/DefaultTheme.scss";

// Lazy loading and code splitting -
// Derieved idea from https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const loading = () => <div></div>;

// All layouts/containers
const NonAuthLayout = Loadable({
  loader: () => import("@components/NonAuthLayout"),
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
  loading,
});

const AuthLayout = Loadable({
  loader: () => import("@components/AuthLayout"),
  render(loaded, props) {
    let Component = loaded.default;
    return (
      <>
        <Component {...props} />
        <Firebase />
      </>
    );
  },
  loading,
});

/**
 * Exports the component with layout wrapped to it
 * @param {} WrappedComponent
 */
const withLayout = (WrappedComponent) => {
  const HOC = class extends Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return connect()(HOC);
};

/**
 * Main app component
 */
const App = (props) => {
  const Layout = props.isAuthenticated ? AuthLayout : NonAuthLayout;

  return (
    <BrowserRouter>
      <Switch>
        {routes.map((route, index) => (
          <route.route
            key={index}
            {...route}
            component={withLayout((props) => (
              <Suspense fallback={loading()}>
                {route.noTopBar ? (
                  <NonAuthLayout {...props}>
                    <route.component {...props} />
                  </NonAuthLayout>
                ) : (
                  <Layout {...props}>
                    <route.component {...props} />
                  </Layout>
                )}
              </Suspense>
            ))}
          />
        ))}
      </Switch>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticated(state),
});

export default connect(mapStateToProps)(App);
