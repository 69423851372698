import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyAMeKss7KdJp-TPqXmgpFdvrVX0SVgC5_8",
  authDomain: "envis-5ebd0.firebaseapp.com",
  databaseURL: "https://envis-5ebd0-default-rtdb.firebaseio.com",
  projectId: "envis-5ebd0",
  storageBucket: "envis-5ebd0.appspot.com",
  messagingSenderId: "867295773672",
  appId: "1:867295773672:web:d611b3251618b9d2efda5d",
  measurementId: "G-WX8YCVKBGX"
};
const firebaseApp = firebase.initializeApp(firebaseConfig);
export const db = firebaseApp.database();
